/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import * as R from 'ramda';
import qs from 'qs';
import _axios from 'axios';
import _ from 'lodash';
import bPromise from 'bluebird';
import { INotarySession, INotarySessionStatus, IParticipant } from '../';

interface TermsPayload {
  accepted: boolean;
  econsentPdfUrl: string;
  privacyPolicyPdfUrl: string;
  termsAndConditionsPdfUrl: string;
}

const emptyParticipant = {
  id: '',
  notarySessionId: '',
  status: '',
  firstName: '',
  lastName: '',
  kbaPassed: false,
  user: {
    id: '',
    lastName: '',
    firstName: '',
    email: '',
    notary: { id: '' },
    token: '',
  },
  documentVerified: false,
  kbaEsignTermsAccepted: false,
  isLastRemoteSigner: false,
  hasRemoteSignersRemaining: false,
};

export const consumerRoomApi = () => {
  const axios = ((window as any).axios = _axios.create({
    headers: {
      'x-isiframe': true,
    },
  }));

  const api = {
    get: axios.get,
    post: axios.post,
    patch: axios.patch,

    getInitialData: async (requestId: string, nsId: string) => {
      try {
        const { ns, status, whitelabelId, docs } = await bPromise.props({
          ns: api.getNs(nsId),
          status: api.getNsStatus(nsId),
          whitelabelId: api.getWhitelabelId(),
          docs: api.getNsDocuments(nsId).then((d) =>
            _.map(d, (doc: Record<string, any>) => ({
              ...doc,
              url:
                doc.url ||
                `/consumer/api/notarization/${nsId}/document?orig=1&type=buffer&docId=${doc.id}`,
            }))
          ),
        });

        return {
          // status,
          notarySession: ns,
          status: ns.status,
          notary: ns.notary,
          whitelabelId,
          signers: R.indexBy(R.prop('id'), [
            status.participant,
            ..._.get(status, 'participants'),
          ]),
          // twilio,
          docs,
        };
      } catch (error) {
        console.error('getInitialDataError: ', error);
        return {
          // status,
          notarySession: {},
          status: '',
          notary: { id: '' },
          whitelabelId: '',
          signers: [],
          // twilio,
          docs: [],
        };
      }
    },

    enoteConsent: async (
      nsId: string,
      nsUserId: string,
      data?: { ssn: string; email?: string; phone?: string }
    ) => {
      try {
        return axios.post(
          `/consumer/api/integration/eoriginal/${nsId}/consent/${nsUserId}`,
          data
        );
      } catch (error) {
        console.error('enoteConsenError', error);
        return false;
      }
    },

    kbaVerificationTimeout: async (id: string, nsuId: string) => {
      try {
        return axios.post(
          `/consumer/api/notarization/${id}/participants/${nsuId}/id-verify-timeout`
        );
      } catch (error) {
        console.error('kbaVerificationTimeoutError', error);
        return false;
      }
    },

    checkKbaCompliance: async (id: string) => {
      try {
        const response = await axios({
          method: 'PATCH',
          url: `/consumer/api/compliance/${id}/kba-check`,
        });
        return response;
      } catch (error) {
        console.error('kbaVerificationTimeoutError', error);
        return false;
      }
    },

    isValidCoupon: async (code: string, userId: string) => {
      try {
        const response = await axios.post(
          '/consumer/api/coupon/valid',
          {},
          { headers: { code, userId } }
        );
        return response;
      } catch (error) {
        console.error('kbaVerificationTimeoutError', error);
        return false;
      }
    },

    postVADisclaimer: async (
      id: string,
      nsuId: string,
      data: Record<string, any>
    ) => {
      try {
        const response = await axios({
          url: `/consumer/api/notarization/${id}/participants/${nsuId}/va-disclaimer`,
          method: 'POST',
          data,
        }).then(R.prop('data'));
        return response;
      } catch (error) {
        console.error('kbaVerificationTimeoutError', error);
        return false;
      }
    },

    // the id returned from this endpoint is used for org->enl for the firebase rtdb path /organization/:whitelabelId/{sessions,rooms}
    getWhitelabelId: async () => {
      try {
        const response = await axios
          .get<{ id: string }>(`/consumer/api/whitelabel`)
          .then(R.prop('data'))
          .then(R.prop('id'));
        return response;
      } catch (error) {
        console.error('kbaVerificationTimeoutError', error);
        return '';
      }
    },

    getParticipant: async (nsId: string, nsUserId: string) => {
      try {
        const response = (await axios
          .get(`/consumer/api/notarization/${nsId}/participants/${nsUserId}`)
          .then(R.prop('data'))) as IParticipant;
        return response;
      } catch (error) {
        console.error('kbaVerificationTimeoutError', error);
        const response: IParticipant = emptyParticipant;

        return response;
      }
    },

    sendEvent: async ({ type, data }: { type: string; data: any }) => {
      try {
        const response = await axios({
          method: 'PATCH',
          url: '/consumer/api/notarization/event',
          data: { type, data },
        }).then(R.prop('data'));
        return response;
      } catch (error) {
        console.error('kbaVerificationTimeoutError', error);
        return false;
      }
    },

    postSecondaryId: async (id: string, nsuId: string, payload?: FormData) => {
      try {
        const response = await axios({
          method: 'POST',
          url: `/consumer/api/notarization/${id}/participants/${nsuId}/secondary-id`,
          data: payload,
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        return response;
      } catch (error) {
        console.error('kbaVerificationTimeoutError', error);
        return false;
      }
    },

    getTwilioTokenForUser: async (nsId: string, nsUserId: string) => {
      try {
        const response = await axios
          .get(
            `/consumer/api/notarization/${nsId}/participants/${nsUserId}/video`
          )
          .then(R.prop('data'));
        return response;
      } catch (error) {
        console.error('kbaVerificationTimeoutError', error);
        return { token: '' };
      }
    },

    getNs: async (nsId: string) => {
      try {
        const response = (await axios
          .get(`/consumer/api/notarization/${nsId}`)
          .then(R.prop('data'))) as INotarySession;
        return response;
      } catch (error) {
        console.error('kbaVerificationTimeoutError', error);
        const response: INotarySession = {
          id: '',
          notary: { id: '' },
          status: {},
        };

        return response;
      }
    },

    getNsUserByPin: async (
      requestId: string,
      pinCode: string,
      termsAccepted: boolean
    ) => {
      try {
        const response = (await axios
          .get(
            `/consumer/api/notarization/room/${requestId}/pin-code/${pinCode}?kbaTermsAccepted=${
              termsAccepted ? 'true' : 'false'
            }`
          )
          .then(R.prop('data'))) as IParticipant;
        return response;
      } catch (error) {
        console.error('kbaVerificationTimeoutError', error);
        const response: IParticipant = emptyParticipant;

        return response;
      }
    },

    getNsStatus: async (nsId: string) => {
      try {
        const response = (await axios
          .get(`/consumer/api/notarization/${nsId}/status`)
          .then(R.prop('data'))) as INotarySessionStatus;
        return response;
      } catch (error) {
        console.error('kbaVerificationTimeoutError', error);
        const response: INotarySessionStatus = {
          participant: emptyParticipant,
          participants: [],
        };

        return response;
      }
    },

    getNsDocuments: async (nsId: string, params: Record<string, any> = {}) => {
      try {
        return await axios({
          url: `/consumer/api/notarization/${nsId}/documents`,
          method: 'GET',
          params: {
            withUrl: 1,
            ...params,
          },
        }).then(R.prop('data'));
      } catch (error) {
        console.error('nsDocumentsError', error);
        return [];
      }
    },

    getNotarizationInvoice: async (
      nsId: string,
      hasRequestedCopies: boolean,
      couponCode: string,
      userId: string
    ) => {
      try {
        const response = await axios
          .get(
            `/consumer/api/notarization/${nsId}/payments?hasRequestedCopies=${hasRequestedCopies}&couponCode=${couponCode}&userId=${userId}&noIntent=1`
          )
          .then(R.prop('data'));
        return response;
      } catch (error) {
        console.error('kbaVerificationTimeoutError', error);
        return { hasRequestedCopies: false, items: [], paymentIntentSecret: null, total: 0 };
      }
    },

    postNotarizationPayment: async (nsId: string, data: any) => {
      try {
        const response = await axios.post(
          `/consumer/api/notarization/${nsId}/payments`,
          data
        );
        return response;
      } catch (error) {
        console.error('kbaVerificationTimeoutError', error);
        return false;
      }
    },

    submitTermsAgreement: async (
      nsId: string,
      nsUserId: string,
      payload: TermsPayload
    ) => {
      try {
        const response = await axios({
          method: 'PATCH',
          url: `/consumer/api/notarization/${nsId}/participants/${nsUserId}/terms`,
          data: {
            ...payload,
          },
        });
        return response;
      } catch (error) {
        console.error('kbaVerificationTimeoutError', error);
        return false;
      }
    },

    initVerification: async (
      id: string,
      nsuId: string,
      data: Record<string, any>,
      provider?: string
    ) => {
      try {
        const response = await axios({
          method: 'POST',
          url: `/consumer/api/notarization/${id}/participants/${nsuId}/id-verify-start`,
          params: { provider },
          data,
        });
        return response;
      } catch (error) {
        console.error('kbaVerificationTimeoutError', error);
        return false;
      }
    },

    getNotarizationStatus: async (nsId: string) => {
      try {
        const response = await axios({
          url: `/consumer/api/notarization/${nsId}/status`,
          method: 'GET',
        }).then(R.prop('data'));
        return response;
      } catch (error) {
        console.error('kbaVerificationTimeoutError', error);
        return { page: '' };
      }
    },

    getIdVerificationQuestions: async (nsId: string, nsuId: string) => {
      try {
        const response = await axios
          .get(
            `/consumer/api/notarization/${nsId}/participants/${nsuId}/id-verify-questions`
          )
          .then(R.prop('data'));
        return response;
      } catch (error) {
        console.error('kbaVerificationTimeoutError', error);
        return [];
      }
    },

    postIdVerificationAnswers: async (
      id: string,
      nsuId: string,
      data: Record<string, any>
    ) => {
      try {
        const response = await axios.post(
          `/consumer/api/notarization/${id}/participants/${nsuId}/id-verify-answers`,
          data
        );
        return response;
      } catch (error) {
        console.error('kbaVerificationTimeoutError', error);
        return false;
      }
    },

    getDocVerifyUrl: async (nsId: string, nsuId: string, opts = {}) => {
      try {
        const query = qs.stringify(opts);

        const response = await axios({
          url: `/consumer/api/notarization/${nsId}/participants/${nsuId}/document-verification?${query}`,
          method: 'GET',
        });
        return response;
      } catch (error) {
        console.error('kbaVerificationTimeoutError', error);
        return { data: '', status: '' };
      }
    },

    getRetryDocVerifyUrl: async (nsId: string, nsuId: string) => {
      try {
        const response = await axios.get(
          `/consumer/api/notarization/${nsId}/participants/${nsuId}/document-verification-retry`
        );
        return response;
      } catch (error) {
        console.error('kbaVerificationTimeoutError', error);
        return { data: '' };
      }
    },

    failNotarization: async (
      nsId: string,
      nsUserId: string,
      reason: string
    ) => {
      try {
        const response = await axios({
          method: 'POST',
          url: `/consumer/api/notarization/${nsId}/fail`,
          data: { reason, nsUserId },
        });
        return response;
      } catch (error) {
        console.error('kbaVerificationTimeoutError', error);
        return false;
      }
    },
  };

  return api;
};

export default consumerRoomApi;
