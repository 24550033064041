/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import * as R from 'ramda';
import _ from 'lodash';
import _axios, { AxiosRequestConfig } from 'axios';
import bPromise from 'bluebird';

import { IRevisionPayload, IUser, INotaryImages, IParticipant, IRevision } from '../';

/*
  This function sets some basic headers that must be present on all requests, while also letting
  us add any additional headers we want.

  The reason this function was added was that previously the setToken() method below was
  overwriting the headers, causing unexpected behaviors like losing the x-isiframe header
  that is required for certain functionalities.
*/
const setInterceptorConfiguration = (
  config: AxiosRequestConfig,
  token: string,
  additionalHeaders: {[key: string]: string} = {}
) => {
  const isIframe = new URLSearchParams(document.location.search).get('iframe') === 'true';

  const headers = _.chain({
    Authorization: `Bearer ${token}`,
    'x-isiframe': isIframe,
    ...config.headers,
    ...additionalHeaders
  })
  .toPairs()
  .filter(([key, val]) => !_.isEmpty(val))
  .fromPairs()
  .value();

  return {
    ...config,
    headers: {
      ...config.headers,
      common: {
        ...(_.get(config, 'headers.common', {})),
        ...headers,
        'x-isiframe': isIframe === true ? 'true' : 'false',
      }
    }
  }
};


export const roomApi = (_token: string) => {
  (window as any).axios = _axios;
  let token = _token;
  const axios = (window as any).axios = _axios.create();


  axios.interceptors.request.use((config) => setInterceptorConfiguration(config, token));

  const roomApi = {
    instance: axios,
    get: axios.get,
    post: axios.post,
    patch: axios.patch,

    getEoData: (enoteId: string) =>
      axios.get(`/api/integration/eoriginal/${enoteId}/eodata`),
    signEnote: (nsId: string, nsUserId: string, payload: { image: string }) =>
      axios.post(
        `/api/integration/eoriginal/${nsId}/participants/${nsUserId}/sign`,
        payload
      ),
    setToken: (_token: string) => {
      token = _token;
      localStorage.setItem('token', token);

      (axios.interceptors.request as any).handlers.forEach(
        (_: any, i: number) => axios.interceptors.request.eject(i)
      );

      axios.interceptors.request.use((config) =>
        setInterceptorConfiguration(config, token, {
          'Cache-Control': '',
        })
      );
    },
    getToken: () => {
      return token;
    },

    updateNotarizationType: async (
      requestId: string,
      nsId: string,
      type: string
    ) =>
      axios.patch(`/api/request/${requestId}/notary-session/${nsId}/type`, {
        type,
      }),
    updateDocNotarizationType: async (
      requestId: string,
      nsId: string,
      docId: string,
      type: string
    ) =>
      axios.patch(
        `/api/request/${requestId}/notary-session/${nsId}/doc/${docId}/type`,
        { type }
      ),
    updateNotaryJournal: async (
      requestId: string,
      nsId: string,
      sessionDescription: string,
      notarizationFee: number
    ) =>
      axios.patch(`/api/request/${requestId}/notary-session/${nsId}/journal`, {
        sessionDescription,
        notarizationFee,
      }),
    // get initial page data required for notary-room
    getInitialData: async (requestId: string, nsId: string) => {
      const {
        ns,
        participants,
        docs,
        user,
        notaryImages,
        permissions,
        twilio,
      } = await bPromise.props({
        ns: roomApi.getNotarySession(requestId, nsId),
        docs: roomApi.getDocuments(requestId, nsId),
        participants: roomApi
          .getParticipants(requestId, nsId)
          .then(R.indexBy(R.prop('id'))),
        user: roomApi.getUser(),
        permissions: roomApi.getPermissions(),
        notaryImages: roomApi.getNotaryImages(),
        notaryProfile: roomApi.getNotaryProfile(),
        twilio: bPromise.resolve({}),
      });

      return {
        permissions,
        notarySession: ns,
        participants,
        user,
        userId: user.id,
        notary: user.notary,
        status: ns.status,
        signatures: {
          [user.id]: {
            signature: notaryImages.signature,
            initials: notaryImages.initials,
          },
        },
        verifications: {},
        docs: docs,
        signers: participants,
        images: R.omit(['signature', 'initials'], notaryImages),
        twilio,
        isAdminUser: true,
        signerLocation: 'remote',
        userType: 'admin',
      };
    },

    getOrgById: (orgId: string) =>
      axios.get(`/api/organization/${orgId}/info`).then(R.prop('data')),

    getUploadUrl: (
      filename: string,
      gcsRefId?: string,
      contentType?: string,
      isTemp?: boolean
    ) => {
      return axios
        .post('/api/request/notarization/document-url', {
          filename,
          gcsRefId,
          contentType,
          isTemp,
        })
        .then(R.prop('data'));
    },
    uploadToGcs: (url: string, file: File) => {
      return fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': 'application/pdf',
          'x-ms-blob-type': 'BlockBlob',
        }
      });
    },

    sealPdf: (gcsRefId: string, userId: string, location: string) => {
      return axios
        .post('/api/seal-pdf', { gcsRefId, userId, location })
        .then(R.prop('data'));
    },

    getPermissions: () => axios.get('/api/permissions').then(R.prop('data')),

    createRevision: async ({
      requestId,
      nsId,
      docId,
      ...rest
    }: IRevisionPayload) => {
      return axios
        .post(
          `/api/request/${requestId}/notary-session/${nsId}/documents/${docId}/revision`,
          {
            ...rest,
          },
          {}
        )
        .then(R.prop('data')) as Promise<IRevision>;
    },
    getRevisionXfdfUploadUrl: async (
      requestId: string,
      nsId: string,
      docId: string
    ) => {
      return axios
        .get<{ url: string; gcsRefId: string }>(
          `/api/request/${requestId}/notary-session/${nsId}/documents/${docId}/revision-xfdf-url`
        )
        .then(R.prop('data'));
    },

    reassignNotaryRoom: async (
      requestId: string,
      payload: { assignTo: string; assignToOrg: string }
    ) => {
      const { data } = await axios.patch(
        `/api/request/${requestId}/reassign`,
        payload
      );
      return data;
    },

    getNotaryCert: async (notaryId?: string): Promise<ArrayBuffer> =>
      (await axios
        .get('/api/cert', {
          responseType: 'arraybuffer',
          params: !notaryId ? {} : { notaryId },
        })
        .then(R.prop('data'))) as ArrayBuffer,

    getNotaryCertPass: async (notaryId?: string): Promise<string> => {
      const params = !notaryId ? {} : { notaryId: notaryId };
      return (await axios
        .get('/api/cert-info', { params })
        .then(R.prop('data'))
        .then(R.prop('certPass'))) as string;
    },

    getDocuments: async (requestId: string, nsId: string) => {
      const { data } = await axios.get(
        `/api/request/${requestId}/notary-session/${nsId}/documents`,
        {
          params: {
            withUrl: true,
          },
        }
      );

      //  return the fetched documents sorted by order
      return _.sortBy(data, 'order');
    },

    getNotaries: (): Promise<{ notaries: Array<IUser> }> =>
      axios
        .get('/api/organization/notaries', {
          params: {
            accountActive: 'true',
          },
        })
        .then(R.prop('data')),
    sendEvent: ({ type, data }: { type: string; data: Record<string, any> }) =>
      axios
        .patch('/api/internal/notarization/event', { type, data })
        .then(R.prop('data')),
    addSignerSignature: async (
      nsId: string,
      nsUserId: string,
      payload: any
    ) => {
      const {
        data: { data },
      } = await axios.post(
        `/api/internal/notarization/${nsId}/participants/${nsUserId}/signature`,
        payload
      );

      return data;
    },

    addSignerInitials: async (nsId: string, nsUserId: string, payload: any) => {
      const {
        data: { data },
      } = await axios.post(
        `/api/internal/notarization/${nsId}/participants/${nsUserId}/initials`,
        payload
      );

      return data;
    },
    getUser: async () =>
      (await axios.get('/api/user').then(R.prop('data'))) as IUser,
    getNotaryProfile: () => roomApi.getUser().then(R.prop('notary')),
    getNotaryImages: async () =>
      (await axios
        .get('/api/internal/notary-images', {})
        .then(R.prop('data'))) as INotaryImages,
    addParticipant: async (
      requestId: string,
      nsId: string,
      participants: Array<any>
    ) =>
      axios.post(
        `/api/request/${requestId}/notary-session/${nsId}/participants`,
        { participants }
      ),
    getParticipant: async (
      requestId: string,
      nsId: string,
      participantId: string
    ) => {
      const { data } = await axios.get(
        `/api/request/${requestId}/notary-session/${nsId}/participant/${participantId}`
      );
      return data;
    },
    getParticipantUrl: async (
      requestId: string,
      nsId: string,
      organizationId: string
    ) => {
      const { data } = await axios.get(
        `/api/participantUrl/${requestId}/${nsId}/${organizationId}`
      );
      return data;
    },
    kbaCheck: (nsId: string) =>
      axios.patch(`/api/compliance/${nsId}/kba-check`),
    getParticipants: async (requestId: string, nsId: string) => {
      const {
        data: { data },
      } = await axios.get(
        `/api/request/${requestId}/notary-session/${nsId}/participants`
      );

      return data as Array<IParticipant>;
    },
    getVerificationDetails: async (requestId: string, nsUserId: string) => {
      const { data } = await axios.get(
        `/api/request/${requestId}/participants/${nsUserId}/verification`,
        {}
      );

      return data;
    },
    getVideoToken: async (requestId: string, nsId: string) => {
      // await axios.get(`/api/request/${requestId}/notary-session/${nsId}/video`, {});
      const { data } = await axios.get(
        `/api/request/${requestId}/notary-session/${nsId}/video`,
        {}
      );
      return data;
    },
    patchVideoToggle: async (
      requestId: string,
      nsId: string,
      enable: boolean
    ) => {
      const { data } = await axios.patch(
        `/api/request/${requestId}/notary-session/${nsId}/video`,
        enable,
        {}
      );

      return data;
    },
    getTagsDefaultRequiredState: (isIframe: boolean) => {
      if (!isIframe) {
        return false;
      }
      return axios
        .get(
          '/api/organizationSettings/toggleableOrgSetting?settingKey=ronDefaultTagsToRequired&valueKey=defaultTagsToRequired&defaultValue=false'
        )
        .then(({ data }) => {
          return data.defaultTagsToRequired ?? false;
        });
    },
    getNotarySession: async (requestId: string, nsId: string) => {
      const {
        data: { data },
      } = await axios.get(`/api/request/${requestId}/notary-session/${nsId}`, {
        params: {
          partial: true,
          includeRequestGroup: true,
        },
      });

      return data;
    },
    getHistoryRequest: async (requestId: string, nsId: string) => {
      const data = await axios.get(
        `/api/request/${requestId}/notary-session/${nsId}/history`,
        {
          headers: token ? { authorization: `Bearer ${token}` } : {},
        }
      );

      return data;
    },
    stopNotarySession: async (requestId: string, nsId: string) => {
      const {
        data: { data },
      } = await axios.post(
        `/api/request/${requestId}/notary-session/${nsId}/stop`,
        {}
      );

      return data;
    },
    startNotarySession: async (requestId: string, nsId: string) => {
      const { data } = await axios.post(
        `/api/request/${requestId}/notary-session/${nsId}/start`,
        {}
      );

      return data.data;
    },

    updateNotes: async (requestId: string, nsId: string, notes?: string) => {
      const { data } = await axios.patch(
        `/api/request/${requestId}/notary-session/${nsId}/journal`,
        { additionalNotes: notes }
      );

      return data;
    },
    getCountiesByState: async (commissionState: string) => {
      return await axios
        .get(`/api/counties?stateName=${commissionState}`)
        .then(R.prop('data'));
    },
    setNotaryCounty: async (notaryId: string, payload: Record<string, any>) => {
      return await axios
        .put(`/api/organization/notary/${notaryId}/counties`, payload)
        .then(R.prop('data'));
    },

    mergeXfdf: async (args: {
      nsId: string;
      docId: string;
      title: string;
      gcsRefId: string;
      xfdf: string;
      mergedDocumentBuffer: Uint8Array;
    }) => {
      const { nsId, docId, title, gcsRefId, xfdf, mergedDocumentBuffer } = args;

      // Generate GCS upload URL for XFDF file.
      const { url, gcsRefId: xfdfGcsRefId } = await roomApi.getUploadUrl(
        title,
        `${nsId}/${docId}.xfdf`,
        'application/xml',
        false
      );

      // Create file from XFDF string.
      const enc = new TextEncoder(); // always utf-8
      const xfdfUtf8 = enc.encode(xfdf);

      const xfdfBlob = new Blob([xfdfUtf8], { type: 'application/xml' });
      const file = new window.File([xfdfBlob], `${docId}.xfdf`, {
        type: 'application/xml',
      });

      // Upload XFDF file to GCS.
      await roomApi.uploadToGcs(url, file);



      // Generate GCS upload URL for XFDF file.
      const { url: pdfUrl, gcsRefId: mergedGcsRefId } = await roomApi.getUploadUrl(
        title,
        `${nsId}/${docId}-merged.pdf`,
        'application/pdf',
        true
      );

      // Upload XFDF file to GCS.
      const blob = new Blob([mergedDocumentBuffer]);
      await roomApi.uploadToGcs(pdfUrl, new File([blob], `${docId}-merged.pdf`, { type: 'application/pdf' }));




      return {
        xfdfGcsRefId,
        gcsRefId: mergedGcsRefId,
        // url: mergedFile.url,
      };
    },

    // Method used for uploading a merged PDF to the temp bucket in GCS.
    uploadMergedPdf: async (
      mergedPdfBuffer: Uint8Array,
      documentId: string,
      gcsRefId: string
    ) => {
      const blob = new Blob([mergedPdfBuffer], { type: 'application/pdf' });
      const file = new window.File([blob], `${documentId}.pdf`, { type: 'application/pdf' });

      const formData = new FormData();
      formData.append('file', file);
      formData.append('gcsRefId', gcsRefId);

      return axios
        .post('/api/upload-merged-pdf', formData)
        .then(R.prop('data'));
    },

    completeNotaryRoom: (
      roomId: string,
      nsId: string,
      { countyId = 'eb1f16a9-103c-4ee3-a0ce-e8773e089026', notes = '' }
    ) =>
      axios({
        method: 'POST',
        url: `/api/request/${roomId}/notary-session/${nsId}/finalize`,
        data: {
          countyId,
          notes,
        },
        params: { isIframe: true },
      }).then(R.prop('data')),

    getSignedUrl: (roomId: string, nsId: string, docId: string) =>
      axios({
        method: 'GET',
        url: `/api/request/${roomId}/notary-session/${nsId}/doc/${docId}/signed-url?flattenSpecial=1`,
      })
        .then(R.prop('data'))
        .then(R.prop('url')),
  };

  (window as any).roomApi = roomApi;
  return roomApi;
};

export default roomApi;
